import React, { PropTypes, useEffect, useRef, useState } from 'react'

import ApplyForm, { CONTACT_LABELS } from './Form'

const FORM_URL = 'https://hellscreamraiders.com/api/apply'
const FALLBACK_VALUE = '*Did not respond*'

const ROLES = {
  tank: 'Tank',
  healer: 'Healer',
  melee: 'Melee Dps',
  ranged: 'Ranged Dps',
}

const DATA_LABELS = {
  contact: 'Contact Method',
  class: 'Class',
  role: 'Role',
  message: 'Message for us? (Optional)',
  ...CONTACT_LABELS
}

const CLASSES_ROLES = {
  deathknight: ['melee', 'tank'],
  druid: ['healer', 'melee', 'ranged', 'tank'],
  hunter: ['melee', 'ranged'],
  mage: ['ranged'],
  paladin: ['healer', 'melee', 'tank'],
  priest: ['healer', 'ranged'],
  rogue: ['melee'],
  shaman: ['healer', 'melee', 'ranged', 'tank'],
  warlock: ['ranged'],
  warrior: ['melee', 'tank'],
}

const serializeFormData = formData => {
  const data = {}
  formData.forEach((value, key) => {
    // Skip Capturing first contact field
    if (key === 'contact') {
      return;
    }

    const dataLabel = DATA_LABELS[key]
    let answer = value
    if (key === 'role') {
      answer = ROLES[value]
    }

    data[dataLabel] = answer
  })

  return data
}

const serializeData = formData => {
  const items = []
  Object.keys(formData).map(field => {
    const answer = formData[field]
    const parts = answer.match(/[\s\S]{1,1024}/g) || []

    for (var j = 0; j < parts.length; j++) {
      if (j === 0) {
        items.push({
          name: field,
          value: parts[j] || FALLBACK_VALUE,
          inline: false,
        })
      } else {
        items.push({
          name: field.concat(' (cont.)'),
          value: parts[j] || FALLBACK_VALUE,
          inline: false,
        })
      }
    }
  })

  return {
    username: 'Hellscream Raiders Website Application',
    embeds: [
      {
        fields: items,
        footer: { text: 'Are they worthy to join our guild and raid?' },
      },
    ],
  }
}

const serializeError = error => ({
  username: 'Hellscream Raiders Failure',
  embeds: [
    {
      color: 15158332,
      fields: [
        {
          name: 'Application Type',
          value: 'Website Application',
          inline: false,
        },
        {
          name: 'Internal Error or Error Response from Discord',
          value: 'error: ' + error,
          inline: false,
        },
      ],
      footer: {
        text: 'Tell Buffdanglin to fix this!',
      },
    },
  ],
})

async function postForm(serializedFormData) {
  const response = await fetch(FORM_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(serializeData(serializedFormData)),
  })

  return response
}

async function postError(error) {
  await fetch(FORM_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(serializeError(error)),
  })
}

const Apply = ({ close, article, articleTimeout }) => {
  const [currentClass, setCurrentClass] = useState('deathknight')
  const [contactType, setContactType] = useState('discord')
  const [isPosting, setIsPosting] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const selectRef = useRef(null)

  useEffect(() => {
    // catch cases where form data still persists
    // Refactor away from form...damn template
    if (selectRef?.current) {
      setCurrentClass(selectRef.current.value)
    }

    if (sessionStorage.getItem('hsr-success') === 'true') {
      setIsSuccess(true)
    }
  }, [])

  const onReset = e => {
    setContactType('discord')
    setCurrentClass('druid')
  }

  const onSubmit = e => {
    e.preventDefault()

    const formDom = e.target
    const serializedFormData = serializeFormData(new FormData(formDom))

    setIsPosting(true)

    postForm(serializedFormData)
      .then(response => {
        if (response.status !== 204) {
          throw new Error('Network response was not ok', response)
        }

        const contentType = response.headers.get('content-type')
        if (contentType && contentType.indexOf('application/json') !== -1) {
          return response.json()
        }

        return response.text()
      })
      .then(data => {
        setIsPosting(false)
        setIsSuccess(true)
        sessionStorage.setItem('hsr-success', true)
        formDom.reset()
      })
      .catch(e => {
        console.error(e)
        postError(e).catch(console.error)
      })
  }

  return (
    <article
      id="apply"
      className={`${article === 'apply' ? 'active' : ''} ${
        articleTimeout ? 'timeout' : ''
      }`}
      style={{ display: 'none' }}
    >
      <h2>Apply</h2>
      {isSuccess && (
        <div className="form-success">
          <span className="icon fa-check" />
          <br />
          Thank you for your interest in Hellscream Raiders. Please allow for 24
          - 72 hrs for us to respond!
        </div>
      )}
      {!isSuccess && (
        <ApplyForm
          isPosting={isPosting}
          onSubmit={onSubmit}
          onReset={onReset}
          currentClass={currentClass}
          setCurrentClass={setCurrentClass}
          contactType={contactType}
          setContactType={setContactType}
          roles={ROLES}
          classesRoles={CLASSES_ROLES}
          selectRef={selectRef}
        />
      )}
      {close}
    </article>
  )
}

Apply.propTypes = {}

export default Apply
