import React, { PropTypes } from 'react'
import BounceLoader from 'react-spinners/BounceLoader'

export const CONTACT_LABELS = {
  'discord': 'Discord Username',
  'battletag': 'BattleTag',
  'ingame': 'Character Name',
  'email': 'Email Address'
}

const CONTACT_PLACEHOLDERS = {
  'discord': 'ChonkyTonk#6839',
  'battletag': 'ThrallForever#1829',
  'ingame': 'Zondermon',
  'email': 'kev@hellscreamraiders.com'
}

const ApplyForm = ({
  roles,
  classesRoles,
  currentClass,
  setCurrentClass,
  setContactType,
  contactType,
  onSubmit,
  onReset,
  isPosting,
  selectRef,
}) => {
  return (
    <form className="form" onSubmit={onSubmit} onReset={onReset}>
      <div className="field half first">
        <label htmlFor="contact">How can we reach you?</label>
        <div className="select-wrapper">
          <select
            name="contact"
            id="contact"
            onChange={e => setContactType(e.target.value)}
            ref={selectRef}
            disabled={isPosting}
          >
            <option value="discord">Discord</option>
            <option value="battletag">Battletag</option>
            <option value="ingame">Character Name</option>
            <option value="email">Email</option>
          </select>
        </div>
      </div>
      <div className="field half">
        <label htmlFor={contactType}>{CONTACT_LABELS[contactType]}</label>
        <input key={contactType} type="text" name={contactType} id={contactType} placeholder={CONTACT_PLACEHOLDERS[contactType]} disabled={isPosting} />
      </div>
      <div className="field half first">
        <label htmlFor="class">Class</label>
        <div className="select-wrapper">
          <select
            name="class"
            id="class"
            onChange={e => setCurrentClass(e.target.value)}
            ref={selectRef}
            disabled={isPosting}
          >
            <option value="deathknight">Death Knight</option>
            <option value="druid">Druid</option>
            <option value="hunter">Hunter</option>
            <option value="mage">Mage</option>
            <option value="paladin">Paladin</option>
            <option value="priest">Priest</option>
            <option value="rogue">Rogue</option>
            <option value="shaman">Shaman</option>
            <option value="warlock">Warlock</option>
            <option value="warrior">Warrior</option>
          </select>
        </div>
      </div>
      <div className="field half">
        <label htmlFor="role">Role</label>
        <div className="select-wrapper">
          <select name="role" id="role" disabled={isPosting}>
            {(classesRoles[currentClass] || []).map(roleKey => (
              <option key={roleKey} value={roleKey}>
                {roles[roleKey]}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="field">
        <label htmlFor="message">Message for us? (Optional)</label>
        <textarea
          name="message"
          id="message"
          rows="4"
          disabled={isPosting}
        ></textarea>
      </div>
      <ul className="actions">
        <li>
          <input
            type="submit"
            value="Apply"
            className="special"
            disabled={isPosting}
          />
        </li>
        <li>
          <input type="reset" value="Reset" disabled={isPosting} />
        </li>
        {isPosting && (
          <li className="actions-loading">
            <BounceLoader color="#ffffff" loading={isPosting} size="40px" />
          </li>
        )}
      </ul>
    </form>
  )
}

export default ApplyForm
