import PropTypes from 'prop-types'
import React from 'react'
import aboutWide from '../images/about_wide.jpg'
import recruitmentExtraWide from '../images/recruitment_extra_wide.jpg'

import pic02 from '../images/pic02.jpg'
// import pic03 from '../images/pic03.jpg'

import Apply from './Tab/Apply'

class Main extends React.Component {
  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
      ></div>
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id="about"
          className={`${this.props.article === 'about' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2>About</h2>
          <span className="image main">
            <img src={aboutWide} alt="" />
          </span>
          <p>
            <b>Hellscream Raiders</b> was founded less than 2 months after launch in 2004 on the Azgalor server. The guild reformed on Faerlina for the launch of Classic WoW. A consistent core has played together since then while making new friends along the way. We were the highest performing casual guild in Classic WoW. Lead then by a keyboard turning undead warrior, we raided all the way into Naxx without requiring world buffs! We have continued into TBC with a new GM and a slightly less casual take on raiding, actually requiring consumes and PvE specs on raid day. We are excited to raid all the way through TBC and we just need your help to make it happen!
            <br />
            <br />
            We are a friendly, inclusive environment. Neither gamer rage nor discrimination are welcome here. Our team is diverse and includes Latinos, mothers, angsty high school kids, no mic trashtypers, and even a few Canadians.
          </p>
          {close}
        </article>

        <article
          id="recruitment"
          className={`${this.props.article === 'recruitment' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2>Recruitment</h2>
          <span className="image main">
            <img src={recruitmentExtraWide} alt="thrall" />
          </span>
          <b>Highest Need</b>
          <div className="column-2">
           <div className="left">
            <ul>
              <li>
                Ele / Enhance Shaman
              </li>
              <li>
                Any Spec Mage 
              </li>
            </ul>
           </div>
           <div className="right">
              <ul>
                <li>
                  Balance Druid
                </li>
                <li>
                  Holy paladin
                </li>
              </ul>
            </div>
          </div>
          <p>Any exceptional players interested in joining our community are welcome to apply! We have a rotating bench for last minute call outs and vacations. </p>
          <b>Raid Schedule</b> (<em>subject to change</em>)
          <div className="column-2">
           <div className="left">
             <b>Ulduar 25 HM</b>: Mon 8-11 PM EST.
             <br />
             <b>TOGC Heroic 25</b>: Wed &nbsp;8-11 PM EST.
             <br />
           </div>
           <div className="right">
            <b>TOGC Heroic 10</b>: Thurs &nbsp;8-9:30 PM EST.
            <br />
            <b>Older Raids</b>: PUGs and GDKPs ran weekly
            <br />
           </div>
          </div>
          <br />
          <b>Loot System</b>
          <br />
          <p>
          Loot Council loot system with a <a href='https://thatsmybis.com'  target="_blank" rel="noopener noreferrer">thatsmybis.com</a> personal priority list.
          </p>
          {close}
        </article>

        <Apply
          close={close}
          article={this.props.article}
          articleTimeout={this.props.articleTimeout}
        />
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
