import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import sample from 'lodash.sample'

const quotes = [
  {
    quote:
      'See your world through my eyes: A universe so vast as to be immeasurable - incomprehensible even to your greatest minds.',
    author: 'Algalon the Observer',
  },
  {
    quote: 'Strike now, heroes, while he is weakened! Vanquish the Deceiver!',
    author: 'Kalec',
  },
  {
    quote:
      'In this world where time is your enemy, it is my greatest ally. This grand game of life that you think you play in fact plays you. To that I say... Let the games begin!',
    author: 'Nefarian',
  },
  {
    quote:
      'I was the first, you know. For me, the wheel of death has spun many times. <laughs> So much time has passed. I have a lot of catching up to do..',
    author: 'Teron Gorefiend',
  },
  {
    quote:
      "Everybody always wanna take from us. Now we gonna start takin' back. Anybody who get in our way...gonna drown in their own blood! De Amani empire be back now...seekin' vengeance. And we gonna start...with you!",
    author: "Zul'Jin",
  },
  {
    quote:
      "Whadaya mean, what kinda accent is dis? It's a troll accent! I swear, ja makin' me crazy.",
    author: 'Rokhan',
  },
  { quote: "Uulwi ifis halahs gag erh'ongg w'ssh", author: 'Yogg-Saron' },
  {
    quote:
      'My demise accomplishes nothing! The master will have you! You will drown in your own blood! The world shall burn! Aaaghh!',
    author: "Kael'thas",
  },
  {
    quote:
      'I have seen worlds bathed in the makers flames; their denizens fading without even a whimper. Entire planetary systems born and raised in the time it takes your mortal hearts to beat once. Yet all throughout, my own heart, devoid of emotion, of empathy, I. have. felt. NOTHING. A million﻿, MILLION lives wasted! Had they all held within them your tenacity? Had they all loved life as you do?',
    author: 'Algalon the Observer',
  },
  { quote: 'This pear, is so delicious...', author: 'Captain Falric' },
  { quote: 'BOOOONEEEESTOOORRMMM', author: 'Lord Marrowgar' },
  {
    quote:
      'GOOD NEWS EVERYONE! I prefected a plague that will wipe out all life on azeroth',
    author: 'Professor Putricide',
  },
  {
    quote:
      "So, the Light's vaunted justice has finally arrived. Shall I lay down Frostmourne and surrender myself to your mercy, Fordring?",
    author: 'Lich King',
  },
  {
    quote:
      'The sun has set on this mortal world, fools. Make peace with your end, for the Hour of Twilight falls!',
    author: 'Deathwing',
  },
  {
    quote:
      "Finish it! Finish it! Kill me, or I swear by the Dragonqueen you'll never see daylight again!",
    author: 'Keristrasza',
  },
  {
    quote: 'There is no good. No evil. No light. There is only POWAH!',
    author: 'Archbishop Benedictus',
  },
  { quote: 'Goriona! Give them hell!', author: 'Warmaster Blackhorn' },
  {
    quote:
      "You landlubbers are tougher than I thought. I'll have to improvise!",
    author: 'Mr. Smite',
  },
  {
    quote: 'The children of Stratholme fought with more ferocity!',
    author: 'Captain Falric',
  },
  { quote: 'LEEEEEEERRROOYYY JENKINS!', author: 'Leeroy Jenkins' },
  { quote: 'All that you know will fade..', author: "Yogg'Saron" },
  {
    quote:
      "Whether the world's greatest gnats or the world's greatest heroes, you're still only mortal!",
    author: 'Stormcaller Brundir',
  },
  {
    quote:
      "A valiant defense, but this city must be razed... I will fulfill Malygos' wishes myself! We finish this now, Champions of the Kirin Tor...",
    author: 'Cyanigosa',
  },
  { quote: 'There must always be....a Lich King.', author: 'Terenes Menethil' },
  {
    quote:
      'Tiny creatures under feet, you bring Garfrost something good to eat!',
    author: 'Garfrost',
  },
  {
    quote:
      'We are the Forsaken. We will slaughter anyone who stands in our way.',
    author: 'Sylvanas Windrunner',
  },
  {
    quote: 'Ha, such an amusing gesture from the rabble.',
    author: 'Scourgelord Tyrannus',
  },
  {
    quote: "I guess it doesn't bend that way.",
    author: 'XT-002 Deconstructor',
  },
  {
    quote: 'Does this red shirt make me look expendable?',
    author: 'Horde Ambassador to the Dragonmaw',
  },
  { quote: 'Arise, My champion.', author: 'High Inquisitor Whitemane' },
  {
    quote:
      'Light grant me one final blessing. Give me the strength to shatter these bonds...',
    author: 'Tirion Fordring',
  },
  { quote: 'TOO SOON EXECUTUS!!!', author: 'Ragnaros' },
  { quote: 'Yes, run! It makes the blood pump faster!', author: "Anub'Rekhan" },
  {
    quote: "Cho'gall will have your heads, ALL OF THEM!!!!",
    author: 'Halfus Wyrmbreaker',
  },
  {
    quote:
      'My people have suffered so long... if only I could shoulder more of their burden.',
    author: 'King Terenas Menethil',
  },
  { quote: 'Garrosh. Garrosh....CAIRNE WAS MY BROTHER!', author: 'Thrall' },
  {
    quote:
      'You speak of justice? Of cowardice? I will show you the justice of the grave and the true meaning of fear.',
    author: 'Lich King',
  },
  {
    quote: 'Gilneas... I... have... failed... you.',
    author: 'King Genn Greymane',
  },
  { quote: 'KILL THEM ALL!', author: 'Corrupted Ashbringer' },
  { quote: 'Do not. Touch. The HAIR.', author: "Lor'thermar Theron" },
  {
    quote:
      'You have won... Maiev... But the huntress... is nothing... without the hunted... You... are nothing... without... me...',
    author: 'Illidan Stormrage',
  },
  {
    quote: 'Dis hurt so bad. Like da time the poop came out sideways.',
    author: 'Orc Sea Pup',
  },
  { quote: 'Me gonna Ook you in the dooker!', author: 'Ook-Ook' },
  { quote: 'We gonna Ook all night!', author: 'Ook-Ook' },
  { quote: 'MADNESS WILL CONSUME YOU ', author: "Yogg'Saron" },
  {
    quote:
      "Algalon was sent here to judge the fate of our world. Cold logic deemed this world was not worth saving. Cold logic, however, does not account for the power of free will. It's up to each of us to prove this is a world worth saving. That our lives... that our lives are worth living.",
    author: 'Rhonin',
  },
  { quote: 'Ha! Darkspear nevah Die', author: "Vol'Jin" },
  { quote: 'I am the lucid dream.', author: 'Sara' },
  {
    quote:
      'Insolent whelps! Your blood will temper the weapons used to reclaim this world!',
    author: 'Ignis',
  },
  { quote: 'Not enough energy.', author: 'Rogue' },
  { quote: 'Not enough rage!', author: 'Warrior' },
  {
    quote:
      'Did you think we had forgotten? Did you think we had forgiven? Behold, now, the terrible vengeance of the Forsaken! Death to the Scourge! And death to the living!',
    author: 'Grand Apothecary Putress',
  },
  {
    quote: 'Stare into the abyss and watch as it blackens your souls!',
    author: 'Grand Apothecary Putress',
  },
  {
    quote:
      'In this world where time is your enemy, it is my greatest ally. This grand game of life that you think you play in fact plays you.',
    author: 'Nefarian',
  },
  { quote: 'You Will Die', author: "C'Thun" },
]

const styles = {
  display: 'flex',
  flexDirection: 'column',
  transition: 'all 600ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  color: 'hsla(0, 0%, 100%, .69)',
  fontSize: '14px',
  maxWidth: '450px',
}

export default class RandomQuote extends PureComponent {
  constructor(props) {
    super(props)
    const { quote, author } = sample(quotes)

    this.state = {
      quote,
      author,
      display: false,
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ display: true })
    }, 350)
  }

  render() {
    return (
      <div
        className={this.props.className}
        style={{ ...styles, opacity: this.state.display ? 1 : 0 }}
      >
        <span>{`"${this.state.quote}"`}</span>
        <span style={{ textAlign: 'right' }}>{`-${this.state.author}`} </span>
      </div>
    )
  }
}

RandomQuote.propTypes = {}
